import {postAction,getAction} from '@/api/tool'
import {api_cart_count,api_claim_get_case_no} from '@/api/api'
import CryptoJS from 'crypto-js'
import moment from 'moment'

export default  {
  computed:{
    //语言包
    L(){
      return this.$store.state.L
    },
    //消息提示-确认按钮
    promptConfirmBtn(){
      return this.$store.state.L.yes 
    },
    //消息提示-取消按钮
    propmtCancelBtn(){
      return this.$store.state.L.no
    }
  },
  data(){
    return {
      officialWebsite:'https://www.inventronics-light.com/contact-us',
      allLoading:false,
      promptCloseFlag:false, //是否可通过点击遮罩关闭
      //分页参数
      paginationObj:{
        currentPage:1, //当前第几页
        pageSize:10, //一页几条
        // pageSize:2, //一页几条
        total:0, //一共几条
        pageSizes:[10, 20, 50, 100]
        // pageSizes:[2, 3, 4, 5]
      },
      //分页排序的参数
      sortInfo:{
        type:'',
        order:''
      }
    }
  },
  methods:{
    goLogin(){
      let newQuery = {}
      if(this.$route.meta.needRecord){
        newQuery = this.$route.query
        newQuery.routeName = this.$route.path.split('/')[1]
      }
      this.$router.push({
        path:'/login',
        query:newQuery
      })
    },
    goRegister(){
      this.$router.push({
        path:'/register'
      })
    },
    goHasSendEmail(){
      this.$router.push({
        path:'/hasSendEmail'
      })
    },
    goWishlist(){
      this.$router.push({
        path:'/wishlist'
      })
    },
    goCustomerSupport(){
      this.$router.push({
        path:'/customerSupport'
      })
    },
    /* begin RMA路由相关的 */
    goRmaList(){
      this.$router.push({
        path:'/rmalist'
      })
    },
    goDraftsRMAlist(){
      this.$router.push({
        path:'/draftsRMAlist'
      })
    },
    goSelectRMAtype(){
      this.$router.push({
        path:'/selectRMAtype'
      })
    },
    goEnquiry(from,draftNo){ //这个flag是claim类型的1234
      this.$router.push({
        path:'/enquiry',
        query:{
          flag:1,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goEnquiry2(from,draftNo){
      this.$router.replace({
        path:'/enquiry',
        query:{
          flag:1,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goEnquiryDetail(caseNo){
      this.$router.push({
        path:'/enquiryDetail',
        query:{
          flag:1, //这个flag是4个claim类型的flag，分别为1234
          caseNo:caseNo
        }
      })
    },
    goCommercial(from,draftNo){ //这个flag是claim类型的1234
      this.$router.push({
        path:'/commercial',
        query:{
          flag:2,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goCommercial2(from,draftNo){
      this.$router.replace({
        path:'/commercial',
        query:{
          flag:2,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goCommercialDetail(caseNo){
      this.$router.push({
        path:'/commercialDetail',
        query:{
          flag:2, //这个flag是4个claim类型的flag，分别为1234
          caseNo:caseNo
        }
      })
    },
    goFieldFailuers(from,draftNo){ //这个flag是claim类型的1234
      this.$router.push({
        path:'/fieldFailuers',
        query:{
          flag:3,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goFieldFailuers2(from,draftNo){
      this.$router.replace({
        path:'/fieldFailuers',
        query:{
          flag:3,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goFieldFailuresDetail(caseNo){
      this.$router.push({
        path:'/fieldFailuresDetail',
        query:{
          flag:3,
          caseNo:caseNo
        }
      })
    },
    goFailuresProd(from,draftNo){ //这个flag是claim类型的1234
      this.$router.push({
        path:'/failuresProd',
        query:{
          flag:4,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goFailuresProd2(from,draftNo){
      this.$router.replace({
        path:'/failuresProd',
        query:{
          flag:4,
          from:from,
          draftNo:draftNo
        }
      })
    },
    goFailuresProdDetail(caseNo){
      this.$router.push({
        path:'/failuresProdDetail',
        query:{
          flag:4,
          caseNo:caseNo
        }
      })
    },
    /* end RMA路由相关的 */
    //-----------------------------------------------------------------
    catalogueUrl(){
      let baseUrl = '/#/catalogue'
      if(!this.$cookies.get('token')){
        baseUrl = baseUrl + '?Catalogue=' + this.getUrlByCountry()
      }
      return baseUrl
    },
    goodListUrl(flag,pimId){
      //参数1，categoryPimid。参数2，familyPimid
      let baseUrl = '/#/goodList'
      let middleUrl = flag==1?'?categoryPimid=':'?familyPimid='
      baseUrl = baseUrl + middleUrl + pimId
      if(!this.$cookies.get('token')){
        baseUrl = baseUrl + '&Catalogue=' + this.getUrlByCountry()
      }
      return baseUrl 
    },
    //产品详情的url
    detailUrl(flag,eanId){
      //参数1,eanId。参数2,familyPimid。参数3,eanIdFromHome
      let baseUrl = '/#/detail'
      let middleUrl = ''
      if(flag==1){
        middleUrl = '?eanId='
      }
      if(flag==2){
        middleUrl = '?familyPimid='
      }
      if(flag==3){
        middleUrl = '?eanIdFromHome='
      }
      baseUrl = baseUrl + middleUrl + eanId
      if(!this.$cookies.get('token')){
        baseUrl = baseUrl + '&Catalogue=' + this.getUrlByCountry()
      }
      return baseUrl 
    },
    quoteDetailUrl(erpQuoteId){
      let url = `/#/quotesDetail?erpQuoteId=${erpQuoteId}`
      return url
    },
    //------------------------------------------------------------------
    /* 
      用到的地方：
      1.登录【不需要加query中的Catalogue参数】
      2.访客进入(含直接进入+选择国家后进入)【需要】
      3.接受用户协议【不需要】
      这里注意下，访客进入的时候才需要加Catalogue的参数，所以就用token判断是不是访客即可
    */
    goCatalogue(){
      let query = {}
      if(!this.$cookies.get('token')){
        query = {
          Catalogue:this.getUrlByCountry()
        }
      }
      this.$router.push({
        path:'/catalogue',
        query:query
      })
    },
    //------------------------------------------------------------------
    goTermEnterOrNoEnter(id,flag=0){
      //如果flag是1的话，就只能去no enter的页面
      if(flag==1){
        return '/#/termNoEnter?id='+id
      }
      //存在token去term
      if(this.$cookies.get('token')){
        return '/#/term?id='+id
      }else{
        //不存在token去termNoEnter
        return '/#/termNoEnter?id='+id
      }
    },
    //------------------------------------------------------------------------------------------------------------------------------
    /* 通过国家找url缩写 */
    getUrlByCountry(){
      let countryUrl = ''
      if(this.$cookies.get('select_country')){
        countryUrl = this.$cookies.get('select_country').split(';')[0]
      }
      return countryUrl
    },
    //回到顶部
    goTop(){
      document.documentElement.scrollTop=0
    },
    //语言切换
    changeLanguage(item){
      this.allLoading=true
      this.$cookies.set('languageId',item.languageId,'180d');
      //这个方法相当于强制刷新整个页面（因为强制刷新的时候必然会请求语言包等接口，所以这里不需要执行任何接口了）
      this.$router.go(0)
    },
    /* 密码检测，至少包含【小写、大写、数字、特殊字符】中的其中3种 */
    passwordCheck(str){
      const rC = {
        lw: /[a-z]/, //小写
        uw: /[A-Z]/, //大写
        nw: /[0-9]/, //数字
        sw: /\W|_/ //特殊字符
      }
      function Reg(str,rStr){
        const reg = rStr
        if(reg.test(str)){
          return true;
        } else {
          return false;
        }
      }
      const tR = {
        l: Reg(str, rC.lw),
        u: Reg(str, rC.uw),
        n: Reg(str, rC.nw),
        s: Reg(str, rC.sw)
      }
      let flag = false
      if(str && str.length >=6 && str.length <= 64){
        if( (tR.l && tR.u && tR.n)||(tR.l && tR.u && tR.s)||(tR.l && tR.n && tR.s)||(tR.u && tR.n && tR.s) ){
          flag=true
        }
      }
      return flag
    },
    /* AES加密 */
    encrypt(data) {
      const key = 'LwUMOY33o952q3ZL' //秘钥
      const iv = 'vHxf0RzYYVWx1fb5' //偏移量
      let result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
      return result.toString()
    },
    /* 获取购物车数量 */
    getCartCount(){
      postAction(api_cart_count).then(res=>{
        this.$store.dispatch('setCartCount',res.itemCount)
      })
    },
    /* 下载文件，flag是1说明是需要预览的，flag是0说明直接下载就行了不需要预览 */
    downloadFile(data,flag=0){
      if(data){
        let fileName = ''
        if(data.headers['content-disposition']){
          fileName = data.headers['content-disposition'].split('=')[1]
        }else{
          let arr = data.config['url'].split('/')
          fileName = arr[arr.length-1]
        }
        //这个判断是为了兼容从欧司朗那边下载文件返回的文件名。
        if(fileName.substr(-1)==='"'){
          fileName=fileName.split('"')[1]
        }
        var blob = new Blob([data.data], {
          type: data.data.type //application/msexcel;charset=utf-8
        });
        const URL = window.URL || window.webkitURL;
        const downloadElement = document.createElement("a");
        const href = URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        //下载后文件名,download 中不指定后缀名，扩展名由浏览器自动检测添加,可能会出错的，所以最好自己添加
        //如果是pdf，并且有传入参数1，说明我这个是需要预览的,就不要download属性
        let nameArr=fileName.split('.')
        //注意这里后缀名一定要取数组的最后一个，因为也许名字里有一个或多个点（如：ZMP_3619227_OT_WI_100_220...240_750_D_NFC_CA_L.pdf）
        if((nameArr[nameArr.length-1]=='pdf' || nameArr[nameArr.length-1]=='PDF') && flag==1){
          //预览，需打开新页面预览
          downloadElement.setAttribute('target', '_blank')
        }else{
          //下载
          downloadElement.download = fileName;
        }
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        // URL.revokeObjectURL(href); // 释放掉blob对象
      }
    },
    /* 格式化时间，后端拿到的时间2022-12-23，展示给客户的时间，23-12-2022 */
    formatDate(date){
      if(!date){
        return
      }
      let arr = date.split('-')
      arr.reverse() //该方法会改变原数组
      return arr.join('-')
    },
    /* 格式化时间，不需要时间点，只需要日期 */
    formatDateNoTime(time){
      if(!time){
        return ''
      }
      return moment(time).format('DD-MM-YYYY')
    },
    /* 动画的几个方法 */
    enter (el) {
      el.style.height = 'auto'
      // noinspection JSSuspiciousNameCombination
      let endWidth = window.getComputedStyle(el).height
      el.style.height = '0px'
      el.offsetHeight // force repaint
      // noinspection JSSuspiciousNameCombination
      el.style.height = endWidth
    },
    afterEnter (el) {
      el.style.height = null
    },
    leave (el) {
      el.style.height = window.getComputedStyle(el).height
      el.offsetHeight // force repaint
      el.style.height = '0px'
    },
    afterLeave (el) {
      el.style.height = null
    },
    /* 格式化金额映射关系 */
    moneyRelation(currency){
      //12.123,00（分隔符，这种类型的，没写进数组的都是反过来的）
      let arr1 = [
        'AED',
        'BGN',
        'CAD',
        'CZK',
        'DKK',
        'EUR',
        'HRK',
        'HUF',
        'NOK',
        'PLN',
        'RON',
        'RUB',
        'SEK',
        'ZAR'
      ]
      //12.123,00 AED(币种展现出来的和后端传过来的一样，并且这个币种是放在最后面的,没写进数组的都是在前面的)
      let arr2 = [
        'AED',
        'BGN',
        'CAD',
        'CZK',
        'DKK',
        'HRK',
        'HUF',
        'NOK',
        'PLN',
        'RON',
        'RUB',
        'SEK',
        'CHF',
        'INR',
        'KRW',
        'MYR',
        'SGD',
        'THB',
        'TRY',
        'TWD',
        'USD',
        'HKD'
      ]
      //€ 12.123,00（展示给客户端的币符号和后端传过来的不一样，并且是展示在前面的）
      let arr3 = [
        {val:'EUR',label:'€'},
        {val:'ZAR',label:'R'},
        {val:'CNY',label:'¥'},
        {val:'GBP',label:'£'},
        {val:'IDR',label:'Rp'},
        {val:'JPY',label:'¥'}
      ]
      //注意，情况2和情况3是互斥的
      let divideInt = arr1.includes(currency)?'.':',' //分隔整数的符号
      let connectDecimal = arr1.includes(currency)?',':'.' //连接小数的符号
      let isAhead = arr2.includes(currency)?false:true //币种符号是不是在前面的
      //如果是3的情况，取一下展示的符号
      if(!arr2.includes(currency)){
        arr3.forEach((val)=>{
          if(val.val===currency){
            currency=val.label
          }
        })
      }
      return {
        currency:currency,
        divideInt:divideInt,
        connectDecimal:connectDecimal,
        isAhead:isAhead
      }
    },
    /* 格式化-金额 */
    formatMoney(num,currency=this.$store.state.currency){
      currency=currency?currency:this.$store.state.currency
      let obj = this.moneyRelation(currency)
      let isNegative = num<0?'-':''
      num=Math.abs(Number(num)).toFixed(5) //toFixed方法会把数字转化成字符串,注意因为这里用了toFixed,所以就算是整数也会自动补5个0，所以下面就一定能取到小数部分
      //把整数部分的数字弄成一个数字，并且反转过来
      let arrInt = num.split('.')[0].split('').reverse() //整数部分数组
      let newArrInt = [] //最终整数部分的数组
      let decimal = num.split('.')[1] //小数部分的值
      let finalVal = '' //最终呈现的值
      arrInt.forEach((val,i)=>{
        newArrInt.push(val)
        if(i>0 && (i+1)%3==0 && (i+1)!=arrInt.length){ //最后一位不用补分隔符
          newArrInt.push(obj.divideInt)
        }
      })
      //五个0的，重置成2个0
      if(!Number(decimal)){
        decimal='00'
      }else{
        //数组反转，转过来去掉尾号是0的那几个数字，再转回去
        decimal=String(Number(decimal.split('').reverse().join(''))).split('').reverse().join('')
        //只有一位的话，补个0,其他情况不用补0
        decimal=decimal.length==1?decimal+'0':decimal
      }
      if(obj.isAhead){
        finalVal = obj.currency + ' ' + isNegative + newArrInt.reverse().join('') + obj.connectDecimal + decimal
      }else{
        finalVal = isNegative + newArrInt.reverse().join('') + obj.connectDecimal + decimal + ' ' + obj.currency
      }
      return finalVal
    },
    /* 分页的两个方法 */
    sizeChange(val){
      this.paginationObj.pageSize=val
      this.getList(2)
    },
    currentChange(val){
      this.paginationObj.currentPage=val
      this.getList()
    },
    /* 必填是否为空 */
    judgeNull(val){
      if(!val){
        return true
      }else{
        return false
      }
    },
    /* 获取当前的日期 */
    getNowDate(){
      let date = moment(new Date()).format('YYYY-MM-DD') //当前日期
      return date
    },
    /* 获取rma的caseNo(这个caseNo仅仅只是单据编号，并不是CRM的case no.) */
    getCaseNo(){
      return new Promise((resolve,reject)=>{
        getAction(api_claim_get_case_no).then(res=>{
          resolve(res)
        }).catch(res=>{
          reject(res)
        })
      })
    },
    /* 获取下拉的文本数据 */
    getSelectText(arr,id){
      let value = ''
      let idArr = id.split(',')
      idArr.forEach(val=>{
        arr.forEach(val2=>{
          if(val==val2.typeId){
            value+=val2.typeName+','
          }
        })
      })
      return value.slice(0,-1)
    },
    /* html转义符解码 */
    decodeHtml(str) {
      let textarea = document.createElement('textarea');
      textarea.innerHTML = str;
      return textarea.value;
    },
    /* 上标字符 */
    superior(num){
      let flag = ''
      /* 小于等于10的，123单独拎出来，7-10都是th */
      if(num<=10){
        if(num==1){
          flag='st'
        }else if(num==2){
          flag='nd'
        }else if(num==3){
          flag='rd'
        }else{
          flag='th'
        }
      }
      /* 大于等于11的， 分123结尾的，和其他结尾的 */
      if(num>=11){
        let lastNum = num % 10 //最后一位
        let lastTwoNum = num % 100 //最后两位
        if(lastNum==1){
          if(lastTwoNum==11){
            flag='th'
          }else{
            flag='st'
          }
        }else if(lastNum==2){
          if(lastTwoNum==12){
            flag='th'
          }else{
            flag='nd'
          }
        }else if(lastNum==3){
          if(lastTwoNum==13){
            flag='th'
          }else{
            flag='rd'
          }
        }else{
          flag='th'
        }
      }
      return flag
    }

  }
}