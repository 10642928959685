<template>
  <div class="search-select-wrap" v-if="show">
    <div class="line" v-for="item in searchSelectList" :key="item.eanPimid" @click="chooseOneResult(item)">
      <span v-html="item.eanName"></span>
      <span class="id">({{item.eanPimid}})</span>
    </div>
  </div>
</template>

<script>
export default {
  name:'SearchSelect',
  props:['searchSelectList'],
  data() {
    return {
      show:false
    }
  },
  methods:{
    /* 选择其中一条 */
    chooseOneResult(item){
      console.log(item,'item')
      this.$emit('chooseOneResult',item)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
.search-select-wrap{
  border-top: 1px solid #ccc;
  &:extend(.main-box-shadow);
  position: absolute;
  left:0;
  top:34px;
  z-index: 9;
  width:calc(100% + 4px);
  background-color: #fff;
  .line{
    color:#444;
    text-align: left;
    font-weight: 400;
    padding:6px 16px;
    .id{
      font-weight: 600;
      margin-left: 10px;
    }
    &:hover{
      background-color: @mainColorOpacity;
      cursor: pointer;
    }
  }
}
</style>