<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap order-details">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text"
          href="/#/quotesList" 
        >{{L.myQuotes}}</a>
        <span class="line">/</span>
        <a
          class="text text2"
          :href="'/#/quotesDetail?erpQuoteId='+$route.query.erpQuoteId" 
        >{{$route.query.erpQuoteId}}</a>
      </div>
      <div class="order-detail-title">
        {{L.quote}} {{$route.query.erpQuoteId}}
      </div>
      <!-- 基本信息 -->
      <div class="basic-information">
        <div class="info-left order-detail-left">
          <div class="customer">
            <div class="title">
              {{L.customerInfo}}
            </div>
            <div class="content">
              <div class="line">
                <div class="left">
                  <span class="left-text1">{{L.shipTo}}:</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.shipTo && orderInfo.shipTo.addressTitle}} {{orderInfo.shipTo && orderInfo.shipTo.postalCode}}</span>
                  <span>{{orderInfo.shipTo && orderInfo.shipTo.addressInfo}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="customer">
            <div class="title">
              {{L.quoteInformation}}
            </div>
            <div class="content">
              <div class="line">
                <div class="left">
                  <span class="left-text1">{{L.quoReference}}</span>
                </div>
                <div class="right">
                  <span class="right-text1">{{orderInfo.quoteReference}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-right order-detail-right">
          <!-- 订单汇总 -->
          <div class="order-summary">{{L.quoteSummary}}</div>
          <div class="summary">
            <div class="left">{{L.quoStatus}}</div>
            <div class="right">{{orderInfo.quoteStatus}}</div>
          </div>
          <div class="summary">
            <div class="left">{{L.quoReference}}</div>
            <div class="right">{{orderInfo.quoteReference}}</div>
          </div>
          <div class="summary">
            <div class="left">{{L.quoteValidFromTo}}</div>
            <div class="right">
              <span class="date1">{{formatDateNoTime(orderInfo.validFrom)}}</span>
              <span class="transverse-line">-</span>
              <span class="date2">{{formatDateNoTime(orderInfo.validTo)}}</span>
            </div>
          </div>
          <div class="total-price" v-if="haveSP">
            <div class="price-left">
              <!-- 不含税总金额 -->
              <div class="total">{{L.totalPrice}}</div>
            </div>
            <div class="price-right">{{formatMoney(orderInfo.totalAmount)}}</div>
          </div>
          <div class="total-items">
            <!-- 个产品项 -->
            <div class="item">{{orderInfo.itemCount}} {{L.quote}} {{L.items}}</div>
          </div>
          <div 
            class="btn-order-confirm active-order" 
            @click="handleXlsx"
          >{{L.quote}} (XLSX)</div>
          <div
            v-if="!showQuotePdf"
            class="btn-order-confirm"
            :class="{'active-order':orderInfo.activeDocument && haveSP}"
            @click="handlePdf"
          >{{L.quote}} (PDF)</div>
          <div 
            class="btn-order-confirm"
            :class="{'active-re-order':orderInfo.canAddToOrder}"
            @click="addQuoteCart"
          >{{L.addQuoteToCart}}</div>
        </div>
      </div>
      <!-- 产品概述 -->
      <div class="product-overview">
        <!-- 大标题 -->
        <div class="title">
          <!-- 订单详情 -->
          <span class="text">{{L.productOverview}}</span>
          <!-- 个产品项 -->
          <span class="quantity">{{orderInfo.itemCount}} {{L.orderltems}}</span>
        </div>
        <!-- 全选 -->
        <div class="select-all">
          <div class="select-message" v-if="orderInfo.noteMsg">{{orderInfo.noteMsg}}</div>
          <div class="select-wrap">
            <el-checkbox :disabled="!orderInfo.canChooseLinesTooOrder" @change="changeSelectAll" v-model="selectAll"><span class="select-text">{{L.selectAllOrNot}}</span></el-checkbox>
          </div>
        </div>
        <!-- 一大行 -->
        <div class="line" v-for="item in orderList" :key="item.erpQuoteLineId">
          <!-- 内容标题 -->
          <div class="line-title">
            <div class="title-left">
              <el-checkbox :disabled="!item.canNoSelected" @change="e=>changeItemSelect(e,item)" v-model="item.hasChoose"></el-checkbox>
            </div>
            <div class="title-right">
              <!-- 预计到货日期 -->
              <div class="date">{{L.quantity}}:</div>
              <!-- 数量 -->
              <div class="quantity">{{L.orderedQty}}:</div>
              <div class="price"></div>
            </div>      
          </div>
          <!-- 主内容 -->
          <div class="main-content">
            <!-- 左边的 -->
            <div class="left">
              <div class="info">
                <div class="text">
                  <div class="text1">
                    <a v-if="!item.eanOrIc.includes('EAN')" class="span1" href="javascript:;" @click="handleSkuName">{{item.productName}}</a>
                    <a v-if="item.eanOrIc.includes('EAN')" class="span1" :href="detailUrl(1,item.eanCode)">
                      <span>{{item.productName}}</span>
                      <span class="phase-out">{{item.phaseOut=='Phase-out'?'(PHASE OUT)':''}}</span>
                    </a>
                  </div>
                  <div class="text2">{{item.eanOrIc}} {{item.productNumber}}</div>
                  <div class="text2" v-for="itemOrder in item.orderNumbers" :key="itemOrder">
                    <a :href="'/#/orderDetail?ebsorderNo='+itemOrder" class="order-text">Order {{itemOrder}}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边的一行 -->
            <div class="right">
              <div class="right-line">
                <!-- 基本内容 -->
                <div class="basic-info">
                  <div class="date">
                    <span>{{item.quantity}}</span>
                  </div>
                  <div class="quantity">
                    {{item.releasedQuantity || 0}}
                  </div>
                  <div class="price">
                    <div class="left-price" v-if="haveSP">
                      <div class="price-num">{{formatMoney(item.lineAmount)}}</div>
                      <div class="carton-box">({{formatMoney(item.unitPrice)}} {{L.per}} {{item.assemblyType}})</div>
                    </div>
                  </div>
                </div>
                <!-- 装箱内容 -->
                <div class="carton-box-remark" v-if="item.qtyMsgList.length>=1 && item.ifVisible==1">
                  <div class="line-title">
                    <div class="date"></div>
                    <div class="quantity">
                      <div class="line-one">
                        <span 
                          class="iconfont icon-arrow"
                          :class="{'icon-active':item.showCarton}"
                          @click="clickCartonFold(item)"
                        >&#xe66c;</span>
                        <span class="text">{{item.qtyMsgList[0]}}</span>
                      </div>
                      <transition
                        @enter="enter"
                        @afterEnter="afterEnter"
                        @leave="leave"
                        @afterLeave="afterLeave"
                      >
                      <div v-show="item.showCarton" class="line-more-wrap">
                        <div class="line-more" v-for="item2,i in item.qtyMsgList" :key="i">{{i>=1?item2:null}}</div>
                      </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 下载那一行 -->
          <div class="download-bottom">
            <div class="download"></div>
            <div class="status">
              <span class="status-text">{{item.lineStatus}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddCartErr ref="AddCartErr" />
  </div>
</template>

<script>
import {api_quote_detail,api_quote_detail_download,api_order_confirm_download,api_quote_add_to_cart} from '@/api/api'
import { downFileGet,downFilePost,postAction,getAction } from '@/api/tool'
import AddCartErr from '@/components/AddCartErr'
import Vue from 'vue'

export default {
  name:'QuotesDetail',
  components:{
    AddCartErr
  },
  data(){
    return {
      orderInfo:{},
      orderList:[],
      selectAll:false //全选
    }
  },
  computed:{
    haveSP(){
      return this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('SP')
    },
    showQuotePdf(){
      let arr = ['OU-GZ','OU-IN','OU-MY','OU-KR','OU-HK','OU-SH','OU-SZ']
      let flag1 = this.$store.state.area=='APAC'
      let flag2 = arr.includes(this.$store.state.ou)
      return flag1 && flag2
    }
  },
  methods:{
    /* 如果不是EAN的，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    },
    /* 获取order详情数据 */
    getOrderDetail(){
      let params={
        quoteNumber:this.$route.query.erpQuoteId
      }
      this.allLoading=true
      postAction(api_quote_detail,params).then(res=>{
        this.allLoading=false
        this.orderInfo=res
        this.orderList=res.detailLines
        //初始化select都为true
        this.selectAll=this.orderInfo.canAddToOrder==1?true:false
        this.changeSelectAll(this.selectAll)
        this.controlBodyChoose()
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 点击展开与折叠装箱信息 */
    clickCartonFold(item){
      this.orderList.forEach((val,i)=>{
        if(item.erpQuoteLineId===val.erpQuoteLineId){
          val.showCarton=!val.showCarton
          this.$set(this.orderList,i,val)
        }
      })
    },
    /* 下载excel */
    handleXlsx(){
      this.allLoading=true
      let params = {
        quoteNumber:this.$route.query.erpQuoteId
      }
      downFilePost(api_quote_detail_download,params).then(res=>{
        this.allLoading=false
        this.downloadFile(res)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载PDF */
    handlePdf(){
      if(!(this.orderInfo.activeDocument && this.haveSP)){
        return
      }
      let params = {
        filezIds:this.orderInfo.activeDocument
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res,1)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 
      单头的canAddToOrder，是1，可以点加入购物车的按钮。
      单头的canAddToOrder，是1，默认全选的是勾上的，是0默认不勾上。
      单头的canChooseLinesTooOrder，是1，能切换select all。
      
      单身的canOrderd，是0，默认不勾选。
      单身的canNoSelected，是1，可以切换。是0，不能切换。
    */

    /* 控制单身首次的hasChoose是true还是false */
    controlBodyChoose(){
      this.orderList.forEach(val=>{
        if(val.canOrderd){
          val.hasChoose=true
        }else{
          val.hasChoose=false
        }
      })
    },
    /* 全选，反选 */
    changeSelectAll(e){
      this.orderList.forEach(val=>{
        //是1的才能切换
        if(val.canNoSelected){
          val.hasChoose=e
        }
      })
    },
    /* 选单个 */
    changeItemSelect(e,item){
      let idx = ''
      let isAllTrue = true
      this.orderList.forEach((val,i)=>{
        if(val.erpQuoteLineId==item.erpQuoteLineId){
          idx = i
          item.hasChoose=e
          if(!e){
            isAllTrue = false
          }
        }else{
          if(!val.hasChoose && val.canNoSelected){
            isAllTrue=false
          }
        }
      })
      this.selectAll=isAllTrue
      this.$set(this.orderList,idx,item) //触发渲染
    },
    /* 添加quote到购物车 */
    addQuoteCart(){
      if(!this.orderInfo.canAddToOrder){
        return
      }
      let params = {
        quoteHeadId:this.orderInfo.quoteHeadId,
        quoteLineIds:[]
      }
      this.orderList.forEach(val=>{
        if(val.hasChoose){
          params.quoteLineIds.push(val.quoteLineId)
        }
      })
      this.allLoading=true
      postAction(api_quote_add_to_cart,params).then(res=>{
        this.allLoading=false
        if(res.code==200){
          this.getCartCount()
          this.$message.success('Successful operation')
        }else{
          this.$refs.AddCartErr.open(res.message)
        }
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.getOrderDetail()
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

