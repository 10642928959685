<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap my-orders">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <a
          class="text1"
          href="/#/selectRMAtype"
        >{{L.myService}}</a>
        <span class="line">/</span>
        <a
          class="text2"
          href="/#/rmaList" 
        >{{L.myTickets}}</a>
      </div>
      <!-- 大标题 -->
      <div class="my-orders-title">
        <div class="left-text">{{L.myTickets}}</div>
        <div class="right-btn-wrap">
          <div class="btn" @click="goDraftsRMAlist">{{L.drafts}}</div>
        </div>
      </div>
      <!-- 搜索 -->
      <div class="search-wrap">
        <div class="square">
          <span class="text">{{L.orderDateFrom}}</span>
          <el-date-picker
            v-model="search.dateFrom"
            class="search-field"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            type="date">
          </el-date-picker>
        </div>
        <div class="square">
          <span class="text">{{L.orderDateTo}}</span>
          <el-date-picker
            v-model="search.dateTo"
            class="search-field"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            type="date">
          </el-date-picker>
        </div>
        <div class="square">
          <span class="text">{{L.caseNo}}:</span>
          <el-input v-model="search.crmCaseNo" @keyup.enter.native="getList(2)" class="search-field"></el-input>
        </div>
        <div class="square">
          <span class="text">{{L.RMAno}}:</span>
          <el-input v-model="search.rmaNo" @keyup.enter.native="getList(2)" class="search-field"></el-input>
        </div>
        <div class="square">
          <span class="text">{{L.caseType}}:</span>
          <el-select :clearable="true" v-model="search.rmaType" class="search-field" placeholder="Please select">
            <el-option
              v-for="item in RMAtypeArr"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

      </div>
      <!-- 按钮 -->
      <div class="btn-wrap">
        <div class="left">
          <div class="null" @click="cleaFilters">{{L.cleaFilters}}</div>
          <div class="fill" @click="getList(2)">{{L.search}}</div>
        </div>
        <!-- <div class="right">
          <div class="fill">My RMA overview (xlsx)</div>
          <div class="fill">RMA detail (xlsx)</div>
        </div> -->
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table
          @sort-change="sortChange"
          :default-sort="{prop:'crmCaseNo',order:'descending'}"
          :data="list"
          style="width: 100%">
          <el-table-column
            prop="crmCaseNo"
            sortable="custom"
            :label="tableLable.caseNo">
              <template slot-scope="scope">
                <a 
                  :href="linkUrl(scope.row)" 
                  class="order-id"
                >{{scope.row.crmCaseNo}}</a>
              </template>
          </el-table-column>
          <el-table-column
            prop="formStatus"
            :label="tableLable.status">
          </el-table-column>
          <el-table-column
            prop="rmaNo"
            :label="tableLable.RMAno">
          </el-table-column>
          <el-table-column
            prop="rmaType"
            :label="tableLable.caseType">
          </el-table-column>
          <el-table-column
            prop="caseDate"
            sortable="custom"
            :label="tableLable.createdDate">
          </el-table-column>
          <el-table-column
            align="center"
            width="130"
            prop="reportFileList"
            label="Technical report">
            <template slot-scope="scope" v-if="scope.row.reportFileList.length>0">
              <span @click="handleDownload(scope.row.reportFileList[0])" class="iconfont icon-pdf">&#xe60a;</span>
            </template>
          </el-table-column>
          <!-- 表格没数据的时候展示空组件，要注意这里的slot=empty不能随意更改 -->
          <div slot="empty">
            <NoContent/>
          </div>
        </el-table>
      </div>
      <Pagination :pageObj="paginationObj" @sizeChange="sizeChange" @currentChange="currentChange"/>
    </div>
    
    
  </div>
</template>

<script>
import NoContent from '@/components/NoContent'
import {api_claim_rma_list,api_order_confirm_download} from '@/api/api'
import {postAction,getAction,downFileGet} from '@/api/tool'
import Pagination from '@/components/Pagination'
import Vue from 'vue'

export default {
  name:'Catalogue',
  components:{
    NoContent,
    Pagination
  },
  data(){
    return {
      RMAtypeArr:[
        {value:'1',label:'Commercial Enquiry'},
        {value:'2',label:'Commercial/Logistic Incident'},
        {value:'3',label:'Field/Application Incident'},
        {value:'4',label:'Production Incident'}
      ],
      search:{
        dateFrom:'',
        dateTo:'',
        crmCaseNo:'',
        rmaType:''
      },
      list:[], //列表数据
      tableLable:{
        caseNo:'',
        status:'',
        RMAno:'',
        caseType:'',
        createdDate:''
      }
    }
  },
  watch:{
    L(newVal){
      this.tableLable=newVal
    }
  },
  methods:{
    /* 获取列表数据 */
    getList(flag=1){
      this.allLoading=true
      this.search.page=flag==2?1:this.paginationObj.currentPage
      this.search.number=this.paginationObj.pageSize
      this.search.formType=1
      this.search.sortInfo=this.sortInfo
      postAction(api_claim_rma_list,this.search).then((res)=>{
        this.paginationObj.total=Number(res.total)
        this.allLoading=false
        this.list=res.data
        console.log(this.list,'list')
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 重置 */
    cleaFilters(){
      this.search={
        dateFrom:'',
        dateTo:'',
        crmCaseNo:'',
        rmaType:''
      }
      this.getList(2)
    },
    /* 跳转详情页链接 */
    linkUrl(obj){
      let url = ''
      if(obj.flag==1){
        url = `/#/enquiryDetail?flag=${1}&caseNo=${obj.caseNo}`
      }
      if(obj.flag==2){
        url = `/#/commercialDetail?flag=${2}&caseNo=${obj.caseNo}`
      }
      if(obj.flag==3){
        url = `/#/fieldFailuresDetail?flag=${3}&caseNo=${obj.caseNo}`
      }
      if(obj.flag==4){
        url = `/#/failuresProdDetail?flag=${4}&caseNo=${obj.caseNo}`
      }
      return url
    },
    /* 表格排序 */
    sortChange(obj){
      if(obj.order=="ascending"){
        this.sortInfo.order='ASC'
      }
      if(obj.order=="descending"){
        this.sortInfo.order='DESC'
      }
      if(obj.prop=="crmCaseNo"){
        this.sortInfo.type=1
      }
      if(obj.prop=="caseDate"){
        this.sortInfo.type=2
      }
      this.getList(2)
    },
    /* 下载文件 */
    handleDownload(obj){
      let params = {
        filezIds:obj.filezId
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    }
  },
  mounted(){
    this.tableLable=this.L
    //最开始进来的时候就按照日期排序
    this.sortInfo.order='DESC'
    this.sortInfo.type=1
    this.getList(2)
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

