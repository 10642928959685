<template>
  <div class="wrap" v-loading="allLoading">
    <!-- cookie提示 -->
    <div class="cookie-wrap" v-show="showArrow.showCookie">
      <!-- 本网站使用cookies和跟踪技术为您提供改进的功能和优化的服务 -->
      <div class="one">{{L.cookieWindowTitle}}</div>
      <!-- 营销cookies暂时不显示 -->
      <div class="two" v-show="false">
        <el-checkbox v-model="checkedCookie1" disabled></el-checkbox>
        <!-- 营销cookies -->
        <span class="label">{{L.marketingCookies}} : </span>
        <span>{{L.marketingCookiesContent}}</span>
      </div>
      <div class="two">
        <el-checkbox v-model="checkedCookie2"></el-checkbox>
        <!-- 十分必要 -->
        <span class="label">{{L.strictlyNecessary}} : </span>
        <span>{{L.strictlyNecessaryContent}}</span>
      </div>
      <div class="four">
        <div class="left">
          <!-- 确认选择 -->
          <button @click="chooseCookie(1)">{{L.confirmSelect}}</button>
          <!-- 接受所有 -->
          <button @click="chooseCookie(2)">{{L.acceptAll}}</button>
          <!-- 拒绝所有 -->
          <button @click="chooseCookie(3)">{{L.rejectAll}}</button>
        </div>
        <div class="right">
          <!-- Cookie设置可以随时更改。更多详细信息 -->
          <span>{{L.cookieSetting}} : </span>
          <!-- Cookie政策 -->
          <a class="click-policy" :href="goTermEnterOrNoEnter('20004')" target="_blank">{{L.cookiePolicy}}</a>
          <span class="line">|</span>
          <!-- 隐私政策 -->
          <a class="click-policy" :href="goTermEnterOrNoEnter('20003')" target="_blank">{{L.privacyPolicy}}</a>
        </div>
      </div>
    </div>
    <!-- 头部+搜索 -->
    <div class="head-wrap">
      <div class="one main-wrap flex-space-between">
        <!-- 左边logo -->
        <a 
          class="left distance-bottom"
          :href="catalogueUrl()"
        >
          <img src="../../assets/logo_en.png"/>
        </a>
        <!-- 右边菜单 -->
        <div class="right">
          <div class="nick">
            <span 
              class="name cus-name-no-word-break"
              :class="{'account-name':$store.state.userInfo.cusAccountNum>1}"
              v-if="showArrow.showGuestInfo"
              @click="openCustomerList"
            >{{$store.state.userInfo.customerName}}</span>
            <span class="name" v-if="!showArrow.showGuestInfo">{{L.guest}}</span>
            <span class="select-country" @click="showSelectCountry" v-if="!showArrow.showGuestInfo">{{this.$cookies.get('select_country') && this.$cookies.get('select_country').split(';')[1]}}</span>
          </div>
          <!-- 收藏清单 -->
          <div class="box box1" @click="goWishlist" v-show="showArrow.showGuestInfo"> 
            <span class="vertical-line"></span>
            <span class="iconfont iconfont1">&#xe8b9;</span>
            <span class="text no-down-text">{{L.myWishlists}}</span>
          </div>
          <!-- My Service Point -->
          <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SPT')">
            <div class="box box1" @click="goSelectRMAtype" v-show="showArrow.showGuestInfo"> 
              <span class="vertical-line"></span>
              <span class="iconfont iconfont1">&#xe66a;</span>
              <span class="text no-down-text">{{L.myService}}</span>
            </div>
          </template>
          <!-- 服务 -->
          <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SMS')">
            <div 
              class="box box5" 
              @mouseenter="mouseenter(5)" 
              @mouseleave="mouseleave(5)" 
              v-show="showArrow.showGuestInfo"
            > 
              <span class="vertical-line"></span>
              <span class="iconfont iconfont5">&#xe712;</span>
              <!-- 我的服务 -->
              <span class="text">{{L.myServices}}</span>
              <span class="iconfont down" :class="{'active-down':!showArrow.show5}">&#xeb8b;</span>
              <transition
                @enter="enter"
                @afterEnter="afterEnter"
                @leave="leave"
                @afterLeave="afterLeave"
              >
                <div class="popover-wrap" v-show="!showArrow.show5">
                  <!-- 我的订单(包括已出货/已开票) -->
                  <a
                    class="line-box" 
                    href="/#/myOrders"
                  >{{L.myOrders}}</a>
                  <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                    <!-- 隐藏quote -->
                    <a
                      class="line-box" 
                      href="/#/quotesList"
                    >{{L.myQuotes}}</a>
                    <a
                      class="line-box" 
                      href="/#/invoiceList"
                    >{{L.myInvoices}}</a>
                    <a
                      class="line-box" 
                      href="/#/myAccountStatement"
                    >{{L.myAccountStatement}}</a>
                    <a
                      class="line-box" 
                      href="/#/creditNotesList"
                    >{{L.myCreditNotes}}</a>
                    <a
                      class="line-box" 
                      href="/#/debitNotesList"
                    >{{L.myDebitNotes}}</a>
                    <div class="line-box" @click="showPricelist">{{L.myPricelist}}</div>
                  </template>
                </div>
              </transition>
            </div>
          </template>
          <!-- 我的账户 -->
          <div class="box box2" @mouseenter="mouseenter(2)" @mouseleave="mouseleave(2)" v-show="showArrow.showGuestInfo">
            <span class="vertical-line"></span>
            <span class="iconfont iconfont2">&#xe639;</span>
            <!-- 我的账号 -->
            <span class="text">{{L.myAccount}}</span>
            <span class="iconfont down" :class="{'active-down':!showArrow.show2}">&#xeb8b;</span>
            <transition
              @enter="enter"
              @afterEnter="afterEnter"
              @leave="leave"
              @afterLeave="afterLeave"
            >
              <div class="popover-wrap" v-show="!showArrow.show2">
                <!-- 账户/联系人信息 -->
                <a 
                  class="line-box" 
                  href="/#/myAccount" 
                >
                {{L.accountInfo}}</a>
                <!-- 地址本 -->
                <a 
                  class="line-box" 
                  href="/#/address"
                >
                {{L.addressBook}}</a>
              </div>
            </transition>
          </div>
          <!-- 语言 -->
          <div class="box box0" @mouseenter="mouseenter(0)" @mouseleave="mouseleave(0)">
            <span class="vertical-line"></span>
            <span class="iconfont iconfont0">&#xe618;</span>
            <!-- <span class="text">{{$store.state.nowLanguage}}</span> -->
            <span class="text">{{abbrLanguage}}</span>
            <span class="iconfont down" :class="{'active-down':!showArrow.show0}">&#xeb8b;</span>
             <transition
              @enter="enter"
              @afterEnter="afterEnter"
              @leave="leave"
              @afterLeave="afterLeave"
            >
              <div class="popover-wrap" v-show="!showArrow.show0">
                <div
                  class="line-box"
                  v-for="item in $store.state.languageList" 
                  :key="item.languageId"
                  @click="changeLanguage(item)"
                >
                  {{item.languageName}}
                </div>
              </div>
             </transition>
          </div>
          <!-- 登出 -->
          <div class="box" @click="handleLogout" v-show="showArrow.showGuestInfo">
            <span class="vertical-line"></span>
            <span class="iconfont iconfont3">&#xe651;</span>
            <!-- 退出 -->
            <span class="text no-down-text nick-name-no-word-break">{{$store.state.userInfo.username}}</span>
          </div>
          <!-- 登录 -->
          <div class="box" @click="goLogin" v-show="!showArrow.showGuestInfo">
            <span class="vertical-line"></span>
            <span class="iconfont iconfont6">&#xe653;</span>
            <!-- 登录 -->
            <span class="text no-down-text">{{L.login}}</span>
          </div>
        </div>
      </div>
      <div class="two-wrap">
        <!-- 头部第二行 -->
        <div class="main-wrap two">
          <!-- 这里mouseleave和mouseenter分开写是因为在css样式上没办法调整成里面的和高度和外面的高度一致,而且还存在padding不好调整 -->
          <div class="left" @mouseleave="mouseleave('catalogue')">
            <!-- 目录浏览 -->
            <div class="browser-handle" @mouseenter="mouseenter('catalogue')">
              <span class="text">{{L.browseCatalogue}}</span>
              <span class="iconfont catalogue">&#xe600;</span>
            </div>
            <!-- 分类的框框 -->
            <transition
              enter-active-class="animate__animated animate__fadeIn"
            >
              <div class="popover main-wrap" v-show="!showArrow.showCatalogue">
                <el-row class="top" :gutter="24" type="flex" justify="start">
                  <el-col :xs="8" :sm="8" :md="8" :lg="6" class="square-col" v-for="item in catalogueList" :key="item.categoryPimid">
                    <div class="square" @click="chooseFirstCatalogue(item)" :class="{'square-active':item.categoryPimid==chooseFirstCataId?true:false}">{{item.categoryName}}</div>
                  </el-col>
                </el-row>
                <el-row class="bottom" :gutter="24" type="flex" justify="start">
                  <el-col 
                    :xs="8" 
                    :sm="8" 
                    :md="8" 
                    :lg="6" 
                    class="pic-col"
                    v-for="item in catalogueProductList" :key="item.categoryPimid"
                  >
                    <a class="pic-wrap" :href="goodListUrl(1,item.categoryPimid)" @click="goGoodList2">
                      <div class="pic">
                        <img :src="item.categoryImage"/>
                      </div>
                      <div class="text">
                        <span>{{item.categoryName}}</span>
                      </div>
                    </a>
                  </el-col>
                </el-row>
              </div>
            </transition>
          </div>
          <div class="right">
            <!-- 搜索产品 -->
            <div class="text">{{L.searchProduct}}</div>
            <div class="search">
              <!-- 注意这个className在js代码中有用到，并不是给css用的，所以请不要轻易更改 -->
              <input
                class="SearchInput"
                type="text" 
                :placeholder="L.search+'...'" 
                v-model="searchQuery" 
                @keyup.enter="handleSearch(1)" 
                @input="handleInput"
                @focus="handleFocus"
              />
              <span class="iconfont icon-search" @click="handleSearch(1)">&#xe622;</span>
              <SearchSelect ref="SearchSelect" :searchSelectList="searchSelectList" @chooseOneResult="chooseOneResult"/>
            </div>
          </div>
          <div class="cart" v-if="showArrow.showGuestInfo">
            <a href="/#/cart">
              <span class="iconfont icon-cart">&#xe62c;</span>
              <span class="num">{{$store.state.cartCount}}</span>
              <span class="cart-text">{{L.cart}}</span>
            </a> 
          </div>
        </div>
      </div>
    </div>
    <!-- 路由占位显示 -->
    <router-view ref="sonPage" @showCookie="showCookie"></router-view>
    <!-- 尾部 -->
    <div class="foot-wrap">
      <div class="main-wrap foot">
        <div class="left">
          <div class="one">
            <!-- 使用条款 -->
            <a :href="goTermEnterOrNoEnter('20001')" target="_blank">{{L.useTerm}}</a>
            <a :href="goTermEnterOrNoEnter('20003')" target="_blank">{{L.privacyPolicy}}</a>
            <a :href="goTermEnterOrNoEnter('20004')" target="_blank">{{L.cookiePolicy}}</a>
            <!-- <span>Imprint</span>
            <span>Golbal Inventronics Websites</span>
            <span>Newsletter</span> -->
            <a href="/#/general" v-if="$cookies.get('token')">{{L.generalTerm}}</a>
            <a href="/#/guarantee">Guarantee Policy</a>
            <a target="_blank" href="https://www.inventronics-light.com/_resources/Inventronics_eShop_FAQ.pdf" v-show="$cookies.get('token')">{{L.FAQ}}</a>
            <a target="_blank" href="https://www.inventronicsglobal.com">{{L.invWebsite}}</a>
            <!-- <span>Customer Support</span> -->
          </div>
          <div class="two">
            <!-- © 2023, 英飞特电子(杭州)股份有限公司。保留所有权利 -->
            {{L.reserveAllRights}}
          </div>
        </div>
        <div class="arrow" @click="goTop">
          <span class="iconfont">&#xe686;</span>
        </div>
      </div>
    </div>
    <!-- 对话框-选择国家 -->
    <SelectCountry ref="SelectCountry"/>
    <CustomerList ref="CustomerList" />
    <RequestPricelist ref="RequestPricelist" />
  </div>
</template>

<script>
import {api_logout,api_catalogue,api_search_product,api_search_dropdown} from '@/api/api'
import {postAction,getAction} from '@/api/tool'
import merge from 'webpack-merge'
import SelectCountry from '@/components/SelectCountry'
import CustomerList from '@/components/CustomerList'
import RequestPricelist from '@/components/RequestPricelist'
import SearchSelect from './SearchSelect'

export default {
  name: 'Home',
  components:{SelectCountry,CustomerList,SearchSelect,RequestPricelist},
  data(){
    return{
      //图标的箭头,最开始都是true
      showArrow:{
        show0:true, //语言
        show5:true, //服务
        show2:true, //我的账户
        showCatalogue:true,
        showGuestInfo:this.$cookies.get('token')?true:false, //是普通用户还是访客
        showCookie:false //cookie选择对话框
      },
      checkedCookie1:true, //cookie1是否勾选
      checkedCookie2:false, //cookie2是否勾选
      catalogueList:[],//目录列表
      chooseFirstCataId:'',//选择的一级目录的id
      catalogueProductList:[],//目录产品列表
      searchQuery:'', //搜索参数
      selectSearchObj:{}, //当前选中的搜索的对象
      searchSelectList:[] //搜索的下拉结果
    }
  },
  computed:{
    abbrLanguage(){
      let arr = this.$store.state.nowLanguage.split('-')
      return arr[0].trim()
    }
  },
  watch:{
    //在detail页面的时候，如果手动修改了最上面的链接，记得要填充进去input框的内容
    $route(newVal){
      let queryArr = Object.keys(newVal.query)
      if(queryArr.includes('searchQuery')){
        this.searchQuery=newVal.query.searchQuery
      }
    }
  },
  methods:{
    /* 打开pricelist弹窗 */
    showPricelist(){
      this.$refs.RequestPricelist.open()
    },
    /* 打开客户列表 */
    openCustomerList(){
      if(this.$store.state.userInfo.cusAccountNum<=1){
        return
      }
      this.$refs.CustomerList.open()
    },
    mouseenter(flag){
      if(flag==0){
        this.showArrow.show0=false
      }
      if(flag==5){
        this.showArrow.show5=false
      }
      if(flag==2){
        this.showArrow.show2=false
      }
      if(flag=='catalogue'){
        this.showArrow.showCatalogue=false
      }
    },
    mouseleave(flag){
      if(flag==0){
        this.showArrow.show0=true
      }
      if(flag==5){
        this.showArrow.show5=true
      }
      if(flag==2){
        this.showArrow.show2=true
      }
      if(flag=='catalogue'){
        this.showArrow.showCatalogue=true
      }
    },
    goGoodList2(){
      this.showArrow.showCatalogue=true //true不要理解成展示弹框，true是初始值，不展示弹框的
    },
    goCart2(){
      this.$refs.sonPage.focusFlag=1
    },
    /* 由term子组件向上发射触发这个cookie对话框展示或者不展示 */
    showCookie(){
      let token=this.$cookies.get('token')
      let user_id=this.$cookies.get('user_id')
      this.showArrow.showCookie=!this.showArrow.showCookie
      if(token){
        this.checkedCookie2=this.$cookies.get('user_id_'+user_id)==1?true:false
      }else{
        this.checkedCookie2=this.$cookies.get('guest_accept_cookie')==1?true:false
      }
    },
    /* 
      是否展示cookie对话框的逻辑
      1.存在token的情况，去看下当前的用户是不是有cookie的记录，有的话不要弹窗，没有的话弹窗 
      2.不存在token的情况，去看下当前的公共cookie区域是不是有guest_accept_cookie的记录，有的话不要弹窗，没有的话弹窗
    */
    setShowCookie(){
      if(this.$cookies.get('token')){
        if(this.$cookies.get('user_id_'+this.$cookies.get('user_id'))==1){
          this.showArrow.showCookie=false
        }else{
          this.showArrow.showCookie=true
        }
      }
      if(!this.$cookies.get('token')){
        if(this.$cookies.get('guest_accept_cookie')==1){
          this.showArrow.showCookie=false
        }else{
          this.showArrow.showCookie=true
        }
      }
    },
    /* 选择国家 */
    showSelectCountry(){
      this.$refs.SelectCountry.openSelectCountry(1)
    },
    /* 3个设置cookie的按钮 */
    chooseCookie(flag){
      if(flag==2){
        this.checkedCookie2=true
      }
      if(flag==3){
        this.checkedCookie2=false
      }
      
      let token=this.$cookies.get('token')
      let user_id=this.$cookies.get('user_id')
      let val=this.checkedCookie2?1:0
      
      /* 存在token，存在user_id123里 */
      if(token){
        this.$cookies.set('user_id_'+user_id,val,'180d')
      }
      /* 不存在token，存在公共的guest_accept_cookie里 */
      if(!token){
        this.$cookies.set('guest_accept_cookie',val,'180d')
      }
      this.showArrow.showCookie=false
    },
    //退出登录
    handleLogout(){
      // 确定退出登录吗？
      this.$confirm(this.L.sureLogout, {
        confirmButtonText: this.promptConfirmBtn,
        cancelButtonText: this.propmtCancelBtn,
        closeOnClickModal:this.promptCloseFlag,
        type: 'warning',
        customClass:'message-box',
        lockScroll:false,
      }).then(() => {
        this.allLoading=true
        postAction(api_logout).then(()=>{
          this.allLoading=false
          this.$cookies.remove('token')
          this.$cookies.remove('user_id')
          this.$store.dispatch('clearUserInfo')
          this.goLogin()
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 获取目录分类列表 */
    getCatalogue(){
      getAction(api_catalogue).then(res=>{
        this.catalogueList=res
        if(res.length>0){
          this.chooseFirstCataId=res[0].categoryPimid
          this.catalogueProductList=res[0].productCategoryVoList
        } 
      })
    },
    /* 点击一级目录 */
    chooseFirstCatalogue(item){
      this.chooseFirstCataId=item.categoryPimid
      this.catalogueProductList=item.productCategoryVoList
    },
    /* 搜索 */
    handleSearch(flag){
      this.$refs.SearchSelect.show=false
      let params={
        queryParam:flag==1?this.searchQuery:this.selectSearchObj.eanPimid
      }
      getAction(api_search_product,params).then(res=>{
        let newQuery = {
          searchQuery:this.searchQuery
        }
        //不存在token的话，要把国家的缩写带上，带到新的页面去。否则新的页面就没有缩写了
        if(!this.$cookies.get('token')){
          newQuery.Catalogue=this.getUrlByCountry()
        }
        //去family的
        if(res.isFamilyMatch){
          this.$cookies.set('familyIdList',res.familyIdList.join(','))
          //本来就已经在family页面的情况
          if(this.$route.name=='GoodList'){
            if(this.$route.query.searchQuery===this.searchQuery){
              this.$refs.sonPage.getFamilyListBySearchNoPrice()
            }
            this.$router.push({
              query:merge({},newQuery)
            })
          }else{
            //需要去到family页面的情况
            this.$router.push({
              path:'/goodList',
              query:newQuery
            })
          }
        }else{
          //去detail的
          //如果已经是detail页面了的话，要记得修改参数和input框中的保持一致
          //这种情况就是，点了2次搜索的情况。（第一次点先跳过去，第二次点就是第一个if判断了)
          if(this.$route.name=='Detail'){
            //这种情况说明查询了2次或2次以上并且搜索的参数是不变的，这时候要手动调用一下搜索的方法，因为那时候路由是监听不到的，没办法触发这个方法
            //注意这行代码和下面的push的代码的顺序不能交换，交换以后这个逻辑就不准确了，因为路由的参数被改变了
            if(this.$route.query.searchQuery===this.searchQuery){
              this.$refs.sonPage.getSearchList()
            }
            this.$router.push({
              //不要用这行代码，用下面一行的原因是。先通过正常渠道进入到detail页面，而后点击搜索，这时候链接上会有familyPimid和searchQuery2个参数
              //所以用下面一行代码的意思是，先把它弄空，再弄成只有一个searchQuery参数的
              // query:merge(this.$route.query,{searchQuery:this.searchQuery}) 
              query:merge({},newQuery) //修改一下链接上的参数
            })
          }else{
            //如果还不是detail页面，要跳到这个页面上去
            this.$router.push({
              path:'/detail',
              query:newQuery
            })
          }
        }
      })
    },
    /* 输入框文字变动，获取下拉数据 */
    handleInput(){
      let queryParam = this.searchQuery.trim()
      //长度>=3的时候才需要匹配
      if(queryParam.length>=3){
        let params = {
          queryParam:queryParam
        }
        getAction(api_search_dropdown,params).then(res=>{
          this.searchSelectList=res
          if(Array.isArray(this.searchSelectList) && this.searchSelectList.length>0){
            this.$refs.SearchSelect.show=true
          }
        })
      }else{
        this.$refs.SearchSelect.show=false
      }
    },
    /* search框聚焦 */
    handleFocus(){
      this.handleInput()
    },
    /* 子组件触发的父组件的方法，选择了其中一条 */
    chooseOneResult(item){
      this.searchQuery=item.eanPimid
      this.selectSearchObj=item
      this.handleSearch(2)
    },
    /* 点击屏幕上的任何一个位置，把搜索下拉框给隐藏起来 */
    hideSearchDropdown(){
      let that = this
      document.addEventListener('click',function(e){
        //不是点击input本身的话，都可以关闭这个下拉数据源。是input框本身就不要关闭了
        if(e.target.className!='SearchInput'){
          if(that.$refs.SearchSelect){
            that.$refs.SearchSelect.show=false
          }
        }
      })
    }
  },
  mounted(){
    this.hideSearchDropdown()
    /* 
      这几个接口不能放在APP.vue里面执行是因为再进入到主页之前，这些接口都是不需要请求的。
      而放在APP.vue里面执行的那些接口都是不需要token大家公共要用的东西，比如语言包等
    */
    //除了登录注册等页面之外，其他所有的页面都在/home页面下面的，所以就算是当前页面刷新的情况，这几个方法也是会走进来的，所以就算刷新cookie的对话框也可以正常显示
    this.setShowCookie()
    this.getCatalogue()
    //这个数据有token的情况下才需要获取
    if(this.$cookies.get('token')){
      this.getCartCount()
    }
    //在手动刷新程序的时候，要把input框里的内容给放回去
    let queryArr = Object.keys(this.$route.query)
    if(queryArr.includes('searchQuery')){
      this.searchQuery=this.$route.query.searchQuery
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>