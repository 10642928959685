<template>
  <div class="tracking-link-wrap">
    <div class="icon-close" @click="closeModal">
      <span class="iconfont icon1">&#xe614;</span>
      <span class="iconfont icon2">&#xe606;</span>
    </div>
    <div class="link-content-wrap">
      <div class="link-content" v-for="item in nowItem.carrierTrackingLinkList" :key="item.carrierTrackName">
        <a :href="item.carrierTrackLink" target="_blank" class="text">{{item.carrierTrackName}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'CarrierTrackingLink',
  data() {
    return {
      nowItem:{} //当前被选中的那一项
    }
  },
  methods:{
    //设置当前选中的产品参数
    setNowProductItem(item){
      this.nowItem=item
    },
    closeModal(){
      this.$emit('closeCarrierLink',this.nowItem)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
.tracking-link-wrap{
  padding:10px 16px 16px 16px;
  box-shadow:0 0 18px 6px rgba(135, 131, 131, 0.5);
  background-color: #fff;
  border-radius: 4px;
  border:1px solid #ccc;
  .icon-close{
    // background-color: red;
    position: absolute;
    right:14px;
    top:14px;
    .icon1{
      font-size: 20px;
      display: block;
      color:#888;
    }
    .icon2{
      font-size: 20px;
      display: none;
      color:@mainColor;
    }
    &:hover{
      cursor: pointer;
      .icon1{
        display: none;
      }
      .icon2{
        display: block;
      }
    }
  }
  .link-content-wrap{
    .link-content{
      margin-top: 10px;
      .text{

        color:@mainColor;
        &:hover{
          font-weight: 800;
          cursor: pointer;
        }
      }
    }
  }
}
</style>